import {
    InjectionToken,
    ModuleWithProviders,
    NgModule,
    Optional,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';

export const BASE_URL = new InjectionToken('WidgetsServerApiModule.BASE_URL');

@NgModule({})
export class WidgetsServerApiModule {
    static forRoot(
        baseUrl: string
    ): ModuleWithProviders<WidgetsServerApiRootModule> {
        return {
            providers: [{ provide: BASE_URL, useValue: baseUrl }],
            ngModule: WidgetsServerApiRootModule,
        };
    }
}

@NgModule({})
export class WidgetsServerApiRootModule {
    constructor(@Optional() http: HttpClient | null) {
        if (!http) {
            throw new Error(
                `Please import "HttpClientModule" in your application module, before using this api service`
            );
        }
    }
}
