import {
    AfterViewInit,
    ApplicationRef,
    ChangeDetectorRef,
    Directive,
    DoCheck,
    HostBinding,
    Injectable,
    Injector,
    Input,
    OnDestroy,
} from '@angular/core';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Injectable()
export class Disposable implements OnDestroy {
    private destroySubject = new Subject();
    protected destroyed = this.destroySubject.asObservable();

    constructor(protected componentInjector: Injector) {}

    ngOnDestroy(): void {
        this.destroySubject.next();
        this.destroySubject.complete();
    }
}

@Directive()
export abstract class BaseComponent
    extends Disposable
    implements DoCheck, AfterViewInit
{
    constructor(injector: Injector) {
        super(injector);
        this.isDisabledSubject
            .pipe(takeUntil(this.destroyed))
            .subscribe((isDisabled) => (this.lastDisabledValue = isDisabled));
        this.isLoadingSubject
            .pipe(takeUntil(this.destroyed))
            .subscribe((isLoading) => (this.lastLoadingValue = isLoading));

        const applicationRef = injector.get(ApplicationRef);

        this.changeDetectorRef = injector.get(ChangeDetectorRef);

        applicationRef.isStable
            .pipe(
                takeUntil(this.destroyed),
                filter((isStable) => isStable),
                filter(() => this.updatedToBeMade.length > 0)
            )
            .subscribe(() => this.doUpdates());
    }

    private changeDetectorRef: ChangeDetectorRef;

    private updatedToBeMade: (() => void)[] = [];
    protected viewInitialized = false;

    ngDoCheck() {}

    ngAfterViewInit() {
        this.viewInitialized = true;
    }

    protected safeUpdate(update: () => void) {
        this.updatedToBeMade.push(update);
    }

    @HostBinding('class.component')
    protected displayContents = true;

    @Input('class') classes: string = '';

    extendByClasses(...styles: object[]) {
        return styles.reduce(
            (prev, cur) => ({ ...prev, ...cur }),
            this.classesAsNgClassObject
        );
    }

    get classesAsNgClassObject() {
        return this.classes != '' ? { [this.classes]: true } : {};
    }

    private lastLoadingValue = false;

    @Input()
    set loading(isLoading: boolean | Subscription | undefined | null) {
        if (isLoading instanceof Subscription) {
            this.isLoadingSubject.next(true);
            isLoading.add(() => {
                this.loading = false;
            });
        } else {
            this.isLoadingSubject.next(!!isLoading);
        }
    }

    get loading() {
        return this.lastLoadingValue;
    }

    private isLoadingSubject = new ReplaySubject<boolean>(1);
    public isLoading = this.isLoadingSubject.asObservable();

    private lastDisabledValue?: boolean;

    @Input()
    set disabled(isDisabled: boolean | Subscription | undefined | null) {
        if (isDisabled instanceof Subscription) {
            this.isDisabledSubject.next(true);
            isDisabled.add(() => {
                this.disabled = false;
            });
        } else {
            this.isDisabledSubject.next(!!isDisabled);
        }
    }

    get disabled(): boolean {
        return !!this.lastDisabledValue;
    }

    private isDisabledSubject = new ReplaySubject<boolean>(1);
    public isDisabled = this.isDisabledSubject.asObservable();

    protected doUpdates() {
        if (this.updatedToBeMade.length) {
            this.updatedToBeMade.forEach((update) => update());
            this.updatedToBeMade = [];
        }
        this.changeDetectorRef.detectChanges();
    }
}
