import { Observable, OperatorFunction, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationConfiguration, NotificationEvent } from './models';

export function handleErrorWith<TSource>(
    handler: (err: any) => NotificationConfiguration | null
): OperatorFunction<TSource, TSource> {
    return function (source: Observable<TSource>) {
        return source.pipe(
            catchError((err) => {
                const notificationConfiguration = handler(err);
                showNotification(notificationConfiguration);
                return throwError(err);
            })
        );
    };
}

export function showNotification(configuration?: NotificationConfiguration | null) {
    if (!configuration) {
        return;
    }
    window.dispatchEvent(new NotificationEvent(configuration));
}

export function ShowsErrorNotification<T extends { [key: string]: any }, TPropertyKey extends keyof T>(
    getConfig: (this: T, exception: Error, ...args: Parameters<T[TPropertyKey]>) => NotificationConfiguration = () =>
        new NotificationConfiguration()
) {
    return function (target: T, propertyKey: keyof T, descriptor: TypedPropertyDescriptor<(...args: any) => any>) {
        const oldFunction = descriptor.value;

        if (!oldFunction) {
            return;
        }

        descriptor.value = function (this: T, ...args) {
            const errorHandler = (ex: any) => {
                const config = getConfig.call(this, ex, ...args);
                showNotification(config);
                throw ex;
            };
            try {
                const promise = oldFunction.call(this, ...args);
                if (promise instanceof Promise) {
                    return promise.catch(errorHandler);
                } else {
                    return promise;
                }
            } catch (ex) {
                errorHandler(ex);
            }
        };
    };
}

export function ShowsSuccessNotification<T extends { [key: string]: any }, TPropertyKey extends keyof T>(
    getConfig: (
        this: T,
        result: ReturnType<T[TPropertyKey]>,
        ...args: Parameters<T[TPropertyKey]>
    ) => NotificationConfiguration = () => new NotificationConfiguration()
) {
    return function (target: T, propertyKey: TPropertyKey, descriptor: TypedPropertyDescriptor<(...args: any) => any>) {
        const oldFunction = descriptor.value;

        if (!oldFunction) {
            return;
        }

        descriptor.value = function (this: T, ...args) {
            const successHandler = (result: T[TPropertyKey]) => {
                const config = getConfig.call(this, result, ...args);
                showNotification(config);
                return result;
            };
            try {
                const promise = oldFunction.call(this, ...args);
                if (promise instanceof Promise) {
                    return promise.then(successHandler);
                } else {
                    return successHandler(promise);
                }
            } catch (ex) {
                throw ex;
            }
        };
    };
}
