import { HttpErrorResponse } from '@angular/common/http';
import { ErrorCode } from '../business/models/ErrorCode.model';
import {
    BaseModel,
    EnumProperty,
    hasUnionKeys,
    StrictBaseModelPart,
} from '@dev-stream/utils';

export class SharedApiFailureResponseModel extends BaseModel<SharedApiFailureResponseModel> {
    protected BASE_TYPE = SharedApiFailureResponseModel;
    Header!: string | null;
    Description!: string | null;
    @EnumProperty(() => ErrorCode)
    ErrorCode!: ErrorCode | null;
    Exception!: Error | null;

    public GetDefaultValue(): StrictBaseModelPart<SharedApiFailureResponseModel> {
        return {
            Header: null,
            Description: 'Непредвиденная ошибка',
            ErrorCode: null,
            Exception: null,
        };
    }

    public static isSharedApiFailureResponseModel(
        o: any
    ): o is SharedApiFailureResponseModel {
        return (
            o instanceof SharedApiFailureResponseModel ||
            hasUnionKeys(o, ['Header', 'Description', 'ErrorCode'])
        );
    }
}

export const mapToApiFailureResponseModel = (
    error?: Error | null,
    parseErrorAsJson = false
) => {
    if (error == null) return null;

    if (error instanceof HttpErrorResponse) {
        try {
            const parsedError = parseErrorAsJson
                ? JSON.parse(error.error)
                : error.error;
            if (
                SharedApiFailureResponseModel.isSharedApiFailureResponseModel(
                    parsedError
                )
            ) {
                return new SharedApiFailureResponseModel({
                    Description: parsedError.Description,
                    Header: parsedError.Header,
                    ErrorCode: parsedError.ErrorCode,
                    Exception: error,
                });
            }
        } catch (ex) {}
    }

    return new SharedApiFailureResponseModel({
        Description:
            'Произошла непредвиденная ошибка, сообщите менеджеру что привело к данной ошибке',
        Header: 'Не предвиденная ошибка',
        ErrorCode: ErrorCode.GENERAL_ERROR,
        Exception: error,
    });
};

/**
 * method to get error data from HttpErrorResponse for reuqest with responseType: 'blob'
 */
export const mapToApiFailureResponseModelFromBlobResponse = async (
    error?: Error | null
) => {
    if (error == null) return null;

    if (error instanceof HttpErrorResponse && error.error instanceof Blob) {
        try {
            let blobError = await error.error.text();
            let parsedError = JSON.parse(blobError);

            if (
                SharedApiFailureResponseModel.isSharedApiFailureResponseModel(
                    parsedError
                )
            ) {
                return new SharedApiFailureResponseModel({
                    Description: parsedError.Description,
                    Header: parsedError.Header,
                    ErrorCode: parsedError.ErrorCode,
                    Exception: error,
                });
            }
        } catch (ex) {}
    }

    return new SharedApiFailureResponseModel({
        Description:
            'Произошла непредвиденная ошибка, сообщите менеджеру что привело к данной ошибке',
        Header: 'Не предвиденная ошибка',
        ErrorCode: ErrorCode.GENERAL_ERROR,
        Exception: error,
    });
};
