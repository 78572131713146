import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { TOKEN_OBTAINER } from './tokens';
import { ITokenObtainer } from './token.obtainer';
import { BaseAuthService } from './shared-auth.service';
import { catchError, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
    constructor(@Inject(TOKEN_OBTAINER) private tokenObtainer: ITokenObtainer, private authService: BaseAuthService) {
    }

    private handleError(error: Error, caught: Observable<HttpEvent<any>>) {
        if (error instanceof HttpErrorResponse) {
            if (error.status == 401) {
                this.authService.authenticate();
            }
        }
        return throwError(error);
    }

    private insertTokenIfNeeded(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.tokenObtainer.skipTokenInsertion(req)) {
            const newReq = req.clone({
                setHeaders: {
                    Authorization: `${this.tokenObtainer.obtainToken(req)}`,
                },
            });
            return next.handle(newReq);
        }
        return next.handle(req);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.insertTokenIfNeeded(req, next).pipe(catchError(this.handleError.bind(this)));
    }
}
