import { Inject, Injectable } from '@angular/core';
import { DefaultPolicy, PolicyFailAction, PolicyHandler, PolicyName, TPolicyName } from '../models/auth.models';
import { AUTHORIZE_POLICIES } from '../tokens';

@Injectable()
export class NgAuthorizeService {
    constructor(
        @Inject(AUTHORIZE_POLICIES)
        private policyFactories: PolicyHandler<any>[]
    ) {}

    public findPolicyByName(policyName: PolicyName) {
        return this.policyFactories.find((p) => p.name === policyName);
    }

    async checkPolicy<TPolicy extends DefaultPolicy>(policyName: TPolicyName<TPolicy>): Promise<boolean> {
        const policy = this.findPolicyByName(policyName);
        if (policy) {
            return policy.check();
        }

        throw new Error('Policy not found');
    }

    async doActionOnFail<TPolicy extends DefaultPolicy>(
        policyName: TPolicyName<TPolicy>,
        action: PolicyFailAction<TPolicy>
    ) {
        const policy = this.findPolicyByName(policyName);
        if (policy) {
            return policy.doActionOnFail(action);
        }

        throw new Error('Policy not found');
    }
}
