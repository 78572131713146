import { BaseModel, StrictBaseModelPart } from '@dev-stream/utils';

export class ProceedSignedContentRp extends BaseModel<ProceedSignedContentRp> {
    BASE_TYPE = ProceedSignedContentRp;
    GetDefaultValue(): StrictBaseModelPart<ProceedSignedContentRp, never> {
        return {
            ProceededSignedContentId: '',
        };
    }

    ProceededSignedContentId!: string;
}
