import { ModuleWithProviders, NgModule } from '@angular/core';
import { NcaLayerService } from './services';

@NgModule({
    declarations: [],
    imports: [],
    exports: [],
    providers: [],
})
export class NgNcalayerModule {
    static forRoot(): ModuleWithProviders<NgNcaLayerRootModule> {
        return {
            ngModule: NgNcaLayerRootModule,
            providers: [NcaLayerService],
        };
    }
}

@NgModule({
    imports: [NgNcalayerModule],
    exports: [NgNcalayerModule],
})
export class NgNcaLayerRootModule {
    constructor(service: NcaLayerService) {
        service.connection.openConnection();
    }

}
