module.exports = {
    transparent: 'transparent',
    current: 'currentColor',
    white: '#ffffff',
    black: '#000000',
    primary: {
        main: '#414141',
        'extra-extra-lighter': '#e8e8e8',
        'extra-lighter': '#c6c6c6',
        'extra-light': '#a0a0a0',
        lighter: '#7a7a7a',
        light: '#5e5e5e',
        dark: '#3b3b3b',
        darker: '#323232',
        'extra-dark': '#2a2a2a',
        'extra-darker': '#232323',
    },
    secondary: {
        main: '#799e90',
        'extra-extra-lighter': '#eff3f2',
        'extra-lighter': '#d7e2de',
        'extra-light': '#bccfc8',
        lighter: '#a1bbb1',
        light: '#8dada1',
        dark: '#719688',
        darker: '#668c7d',
        'extra-dark': '#5c8273',
        'extra-darker': '#497061',
    },
    accent: {
        main: '#23b97d',
        'extra-extra-lighter': '#e5f7ef',
        'extra-lighter': '#bdead8',
        'extra-light': '#91dcbe',
        lighter: '#65cea4',
        light: '#44c491',
        dark: '#1fb275',
        darker: '#18a566',
        'extra-dark': '#0e9158',
        'extra-darker': '#087a4b',
    },
    info: {
        main: '#00afb9',
        'extra-extra-lighter': '#e0f5f7',
        'extra-lighter': '#b3e7ea',
        'extra-light': '#80d7dc',
        lighter: '#4dc7ce',
        light: '#26bbc4',
        dark: '#00a8b2',
        darker: '#009faa',
        'extra-dark': '#0096a2',
        'extra-darker': '#008693',
    },
    success: {
        main: '#a5be00',
        'extra-extra-lighter': '#f4f7e0',
        'extra-lighter': '#e4ecb3',
        'extra-light': '#d2df80',
        lighter: '#c0d24d',
        light: '#b3c826',
        dark: '#9db800',
        darker: '#93af00',
        'extra-dark': '#8aa700',
        'extra-darker': '#799900',
    },
    warning: {
        main: '#f9c74f',
        'extra-extra-lighter': '#fef8ea',
        'extra-lighter': '#fdeeca',
        'extra-light': '#fce3a7',
        lighter: '#fbd884',
        light: '#facf69',
        dark: '#f8c148',
        darker: '#f7ba3f',
        'extra-dark': '#f6b336',
        'extra-darker': '#f5a626',
    },
    danger: {
        main: '#f94144',
        'extra-extra-lighter': '#fee8e9',
        'extra-lighter': '#fdc6c7',
        'extra-light': '#fca0a2',
        lighter: '#fb7a7c',
        light: '#fa5e60',
        dark: '#f83b3e',
        darker: '#f73235',
        'extra-dark': '#f62a2d',
        'extra-darker': '#f51c1f',
    },
    neutral: {
        main: '#9e9e9e',
        'extra-extra-lighter': '#fafafa',
        'extra-lighter': '#f5f5f5',
        'extra-light': '#eeeeee',
        lighter: '#e0e0e0',
        light: '#bdbdbd',
        dark: '#757575',
        darker: '#616161',
        'extra-dark': '#424242',
        'extra-darker': '#212121',
    },
    'on-dark': {
        disabled: 'rgba(250, 250, 250, 0.3)',
        secondary: 'rgba(215, 226, 222, 0.6)',
        danger: '#212121',
        warning: '#212121',
        success: '#212121',
        info: '#212121',
        accent: '#44c491',
        base: '#fafafa',
    },
    'on-middle': {
        disabled: 'rgba(33, 33, 33, 0.3)',
        secondary: 'rgba(73, 112, 97, 0.6)',
        danger: '#f73235',
        warning: '#f5a626',
        success: '#8aa700',
        info: '#0096a2',
        accent: '#18a566',
        base: '#212121',
    },
    'on-light': {
        disabled: 'rgba(33, 33, 33, 0.3)',
        secondary: 'rgba(73, 112, 97, 0.6)',
        danger: '#f94144',
        warning: '#f7ba3f',
        success: '#a5be00',
        info: '#00afb9',
        accent: '#23b97d',
        base: '#212121',
    },
    state: {
        violet: {
            'extra-extra-lighter': '#ede7f6',
            'extra-lighter': '#d1c4e9',
            'extra-light': '#b39ddb',
            lighter: '#9575cd',
            light: '#7e57c2',
            main: '#673ab7',
            dark: '#5e35b1',
            darker: '#512da8',
            'extra-dark': '#4527a0',
            'extra-darker': '#311b92',
        },
        indigo: {
            'extra-extra-lighter': '#e8eaf6',
            'extra-lighter': '#c5cae9',
            'extra-light': '#9fa8da',
            lighter: '#7986cb',
            light: '#5c6bc0',
            main: '#3f51b5',
            dark: '#3949ab',
            darker: '#303f9f',
            'extra-dark': '#283593',
            'extra-darker': '#1a237e',
        },
        blue: {
            'extra-extra-lighter': '#e3f2fd',
            'extra-lighter': '#bbdefb',
            'extra-light': '#90caf9',
            lighter: '#64b5f6',
            light: '#42a5f5',
            main: '#2196f3',
            dark: '#1e88e5',
            darker: '#1976d2',
            'extra-dark': '#1565c0',
            'extra-darker': '#0d47a1',
        },
        cyan: {
            'extra-extra-lighter': '#e0f7fa',
            'extra-lighter': '#b2ebf2',
            'extra-light': '#80deea',
            lighter: '#4dd0e1',
            light: '#26c6da',
            main: '#00bcd4',
            dark: '#00acc1',
            darker: '#0097a7',
            'extra-dark': '#00838f',
            'extra-darker': '#006064',
        },
        teal: {
            'extra-extra-lighter': '#e0f2f1',
            'extra-lighter': '#b2dfdb',
            'extra-light': '#80cbc4',
            lighter: '#4db6ac',
            light: '#26a69a',
            main: '#009688',
            dark: '#00897b',
            darker: '#00796b',
            'extra-dark': '#00695c',
            'extra-darker': '#004d40',
        },
        green: {
            'extra-extra-lighter': '#e8f5e9',
            'extra-lighter': '#c8e6c9',
            'extra-light': '#a5d6a7',
            lighter: '#81c784',
            light: '#66bb6a',
            main: '#4caf50',
            dark: '#43a047',
            darker: '#388e3c',
            'extra-dark': '#2e7d32',
            'extra-darker': '#1b5e20',
        },
        lime: {
            'extra-extra-lighter': '#f9fbe7',
            'extra-lighter': '#f0f4c3',
            'extra-light': '#e6ee9c',
            lighter: '#dce775',
            light: '#d4e157',
            main: '#cddc39',
            dark: '#c0ca33',
            darker: '#afb42b',
            'extra-dark': '#9e9d24',
            'extra-darker': '#827717',
        },
        yellow: {
            'extra-extra-lighter': '#fffde7',
            'extra-lighter': '#fff9c4',
            'extra-light': '#fff59d',
            lighter: '#fff176',
            light: '#ffee58',
            main: '#ffeb3b',
            dark: '#fdd835',
            darker: '#fbc02d',
            'extra-dark': '#f9a825',
            'extra-darker': '#f57f17',
        },
        orange: {
            'extra-extra-lighter': '#fff3e0',
            'extra-lighter': '#ffe0b2',
            'extra-light': '#ffcc80',
            lighter: '#ffb74d',
            light: '#ffa726',
            main: '#ff9800',
            dark: '#fb8c00',
            darker: '#f57c00',
            'extra-dark': '#ef6c00',
            'extra-darker': '#e65100',
        },
        red: {
            'extra-extra-lighter': '#ffebee',
            'extra-lighter': '#ffcdd2',
            'extra-light': '#ef9a9a',
            lighter: '#e57373',
            light: '#ef5350',
            main: '#f44336',
            dark: '#e53935',
            darker: '#d32f2f',
            'extra-dark': '#c62828',
            'extra-darker': '#b71c1c',
        },
        pink: {
            'extra-extra-lighter': '#fce4ec',
            'extra-lighter': '#f8bbd0',
            'extra-light': '#f48fb1',
            lighter: '#f06292',
            light: '#ec407a',
            main: '#e91e63',
            dark: '#d81b60',
            darker: '#c2185b',
            'extra-dark': '#ad1457',
            'extra-darker': '#880e4f',
        },
        purple: {
            'extra-extra-lighter': '#f3e5f5',
            'extra-lighter': '#e1bee7',
            'extra-light': '#ce93d8',
            lighter: '#ba68c8',
            light: '#ab47bc',
            main: '#9c27b0',
            dark: '#8e24aa',
            darker: '#7b1fa2',
            'extra-dark': '#6a1b9a',
            'extra-darker': '#4a148c',
        },
        coldgray: {
            'extra-extra-lighter': '#eceff1',
            'extra-lighter': '#cfd8dc',
            'extra-light': '#b0bec5',
            lighter: '#90a4ae',
            light: '#78909c',
            main: '#607d8b',
            dark: '#546e7a',
            darker: '#455a64',
            'extra-dark': '#37474f',
            'extra-darker': '#263238',
        },
    },
};
