import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    HostBinding,
} from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';
import {
    ButtonColor,
    ButtonRipples,
    ButtonShimmerColors,
    ButtonStyles,
    ButtonType,
    ButtonTypeMap,
    extendStyles,
    mergeStyles,
} from './utils';
import { hexToRgb, tailwindTheme } from '@idocs/tailwind';

@Component({
    selector: 'shared-ui-button, [shared-ui-button]',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent extends BaseComponent implements OnInit {
    @Input()
    tooltip: string | null | undefined;

    @Input()
    appearance: ButtonType = 'default';

    @Input('class')
    classExtension: string = '';

    @HostBinding('style.--icon-size.px')
    @Input()
    iconSize = 24;

    @Input() color: ButtonColor = 'accent';

    @Input()
    @HostBinding('style.--button-position')
    stylePosition:
        | 'absolute'
        | 'fixed'
        | 'relative'
        | 'static'
        | 'sticky'
        | 'inherit' = 'relative';

    static defaultButtonStyles: ButtonStyles = extendStyles(
        {
            primary: [
                'border',
                'border-primary-main',
                'bg-primary-main',
                'text-white',
                'hover:bg-primary-dark',
            ],
            secondary: [
                'border',
                'border-secondary-main',
                'bg-secondary-main',
                'text-white',
                'hover:bg-secondary-dark',
            ],
            accent: [
                'border',
                'border-accent-main',
                'bg-accent-main',
                'text-white',
                'hover:bg-accent-dark',
            ],
            info: [
                'border',
                'border-info-main',
                'bg-info-main',
                'text-white',
                'hover:bg-info-dark',
            ],
            success: [
                'border',
                'border-success-main',
                'bg-success-main',
                'text-white',
                'hover:bg-success-dark',
            ],
            warning: [
                'border',
                'border-warning-main',
                'bg-warning-main',
                'text-white',
                'hover:bg-warning-dark',
            ],
            danger: [
                'border',
                'border-danger-main',
                'bg-danger-main',
                'text-white',
                'hover:bg-danger-dark',
            ],
            neutral: [
                'border',
                'border-neutral-main',
                'bg-neutral-main',
                'text-white',
                'hover:bg-neutral-dark',
            ],
            white: [
                'border',
                'border-white',
                'bg-white',
                'text-on-light-base',
                'hover:bg-neutral-extra-light',
            ],
        },
        [
            'disabled:bg-neutral-light',
            'disabled:text-primary-light',
            'disabled:cursor-not-allowed',
        ]
    );

    static customButtonStyles: ButtonStyles = extendStyles(
        {
            primary: [],
            secondary: [],
            accent: [],
            info: [],
            success: [],
            warning: [],
            danger: [],
            neutral: [],
            white: [],
        },
        [
            'disabled:bg-neutral-light',
            'disabled:text-primary-light',
            'disabled:cursor-not-allowed',
        ]
    );

    static raisedButtonStyles: ButtonStyles = extendStyles(
        ButtonComponent.defaultButtonStyles,
        ['shadow-level-1', 'hover:shadow-level-2', 'disabled:shadow-level-1']
    );

    static linkButtonStyles: ButtonStyles = extendStyles(
        {
            primary: ['text-primary-main'],
            secondary: ['text-secondary-main'],
            accent: ['text-accent-main'],
            info: ['text-info-main'],
            success: ['text-success-main'],
            warning: ['text-warning-main'],
            danger: ['text-danger-main'],
            neutral: ['text-neutral-main'],
            white: ['text-white'],
        },
        ['disabled:text-primary-light', 'disabled:cursor-not-allowed']
    );

    static borderedButtonStyles = extendStyles(
        ButtonComponent.linkButtonStyles,
        ['bg-transparent', 'border', 'disabled:border-neutral-light']
    );

    static outlineButtonStyles: ButtonStyles = mergeStyles(
        ButtonComponent.linkButtonStyles,
        extendStyles(
            {
                primary: [
                    'hover:bg-primary-main',
                    'hover:text-white',
                    'hover:border-primary-main',
                ],
                secondary: [
                    'hover:bg-secondary-main',
                    'hover:text-white',
                    'hover:border-secondary-main',
                ],
                accent: [
                    'hover:bg-accent-main',
                    'hover:text-white',
                    'hover:border-accent-main',
                ],
                info: [
                    'hover:bg-info-main',
                    'hover:text-white',
                    'hover:border-info-main',
                ],
                success: [
                    'hover:bg-success-main',
                    'hover:text-white',
                    'hover:border-success-main',
                ],
                warning: [
                    'hover:bg-warning-main',
                    'hover:text-white',
                    'hover:border-warning-main',
                ],
                danger: [
                    'hover:bg-danger-main',
                    'hover:text-white',
                    'hover:border-danger-main',
                ],
                neutral: [
                    'hover:bg-neutral-main',
                    'hover:text-white',
                    'hover:border-neutral-main',
                ],
                white: [
                    'hover:bg-white',
                    'hover:text-on-light-base',
                    'hover:border-white',
                ],
            },
            ['border', 'disabled:bg-neutral-light', 'bg-white']
        )
    );

    static iconButtonStyles: ButtonStyles = extendStyles(
        {
            primary: ['text-primary-main'],
            secondary: ['text-secondary-main'],
            accent: ['text-accent-main'],
            info: ['text-info-main'],
            success: ['text-success-main'],
            warning: ['text-warning-main'],
            danger: ['text-danger-main'],
            neutral: ['text-neutral-main'],
            white: ['text-white'],
        },
        [
            'bg-transparent',
            'icon-button',
            'rounded',
            'disabled:text-primary-light',
            'disabled:cursor-not-allowed',
        ]
    );

    static roundedButtonStyles: ButtonStyles = extendStyles(
        ButtonComponent.raisedButtonStyles,
        ['rounded-full', 'icon-button']
    );

    static typedStyles: ButtonTypeMap<ButtonStyles> = {
        default: ButtonComponent.defaultButtonStyles,
        icon: ButtonComponent.iconButtonStyles,
        outline: ButtonComponent.outlineButtonStyles,
        raised: ButtonComponent.raisedButtonStyles,
        bordered: ButtonComponent.borderedButtonStyles,
        link: ButtonComponent.linkButtonStyles,
        rounded: ButtonComponent.roundedButtonStyles,
        custom: ButtonComponent.customButtonStyles,
    };

    static defaultStyles = [
        'px-12',
        'py-8',
        'uppercase',
        'font-medium',
        'text-paragraph-2',
    ];

    static defaultButtonRipples: ButtonRipples = {
        primary: hexToRgb(tailwindTheme.colors.primary['extra-dark'], 0.2),
        secondary: hexToRgb(tailwindTheme.colors.secondary['extra-dark'], 0.2),
        accent: hexToRgb(tailwindTheme.colors.accent['extra-dark'], 0.2),
        info: hexToRgb(tailwindTheme.colors.info['extra-dark'], 0.2),
        success: hexToRgb(tailwindTheme.colors.success['extra-dark'], 0.2),
        warning: hexToRgb(tailwindTheme.colors.warning['extra-dark'], 0.2),
        danger: hexToRgb(tailwindTheme.colors.danger['extra-dark'], 0.2),
        neutral: hexToRgb(tailwindTheme.colors.neutral.darker, 0.2),
        white: hexToRgb(tailwindTheme.colors.neutral.light, 0.2),
    };

    static typedButtonRipples: ButtonTypeMap<ButtonRipples> = {
        default: ButtonComponent.defaultButtonRipples,
        raised: ButtonComponent.defaultButtonRipples,
        outline: ButtonComponent.defaultButtonRipples,
        icon: ButtonComponent.defaultButtonRipples,
        bordered: ButtonComponent.defaultButtonRipples,
        link: ButtonComponent.defaultButtonRipples,
        rounded: ButtonComponent.defaultButtonRipples,
        custom: ButtonComponent.defaultButtonRipples,
    };

    static defaultButtonShimmerColor: ButtonShimmerColors = {
        primary: hexToRgb(tailwindTheme.colors.primary.main, 1),
        secondary: hexToRgb(tailwindTheme.colors.secondary.main, 1),
        accent: hexToRgb(tailwindTheme.colors.accent.main, 1),
        info: hexToRgb(tailwindTheme.colors.info.main, 1),
        success: hexToRgb(tailwindTheme.colors.success.main, 1),
        warning: hexToRgb(tailwindTheme.colors.warning.main, 1),
        danger: hexToRgb(tailwindTheme.colors.danger.main, 1),
        neutral: hexToRgb(tailwindTheme.colors.neutral.main, 1),
        white: hexToRgb(tailwindTheme.colors.white, 1),
    };

    static typedButtonShimmerColors: ButtonTypeMap<ButtonShimmerColors> = {
        default: ButtonComponent.defaultButtonShimmerColor,
        raised: ButtonComponent.defaultButtonShimmerColor,
        outline: ButtonComponent.defaultButtonShimmerColor,
        icon: ButtonComponent.defaultButtonShimmerColor,
        bordered: ButtonComponent.defaultButtonShimmerColor,
        link: ButtonComponent.defaultButtonShimmerColor,
        rounded: ButtonComponent.defaultButtonShimmerColor,
        custom: ButtonComponent.defaultButtonShimmerColor,
    };

    get buttonStyles() {
        return [
            ...ButtonComponent.defaultStyles,
            ...ButtonComponent.typedStyles[this.appearance][this.color],
            this.classExtension,
        ];
    }

    get rippleColor() {
        return ButtonComponent.typedButtonRipples[this.appearance][this.color];
    }

    get shimmerColor() {
        return ButtonComponent.typedButtonShimmerColors[this.appearance][
            this.color
        ];
    }

    ngOnInit(): void {}
}
