import { OverlayRef } from '@angular/cdk/overlay';
import { ComponentRef } from '@angular/core';
import { AppColor } from '@idocs/tailwind';
import { BaseModel, StrictBaseModelPart } from '@dev-stream/utils';

export enum NotificationVerticalPosition {
    Start,
    Center,
    End,
}

export enum NotificationHorizontalPosition {
    Start,
    Center,
    End,
}

export type NotificationPosition = {
    vertical: NotificationVerticalPosition;
    horizontal: NotificationHorizontalPosition;
};

export type NotificationContainer<TContainer> = {
    position: NotificationPosition;
    overlayRef: OverlayRef;
    containerRef: ComponentRef<TContainer>;
};

export class NotificationConfiguration extends BaseModel<NotificationConfiguration> {
    protected BASE_TYPE = NotificationConfiguration;

    GetDefaultValue(): StrictBaseModelPart<NotificationConfiguration, never> {
        return {
            content: 'Default notification content',
            color: 'accent',
            duration: 5000,
            title: null,
            position: {
                horizontal: NotificationHorizontalPosition.End,
                vertical: NotificationVerticalPosition.End,
            },
            exception: null,
            throwException: false,
        };
    }

    color!: AppColor;
    title!: string | null;
    content!: string;
    duration!: number;
    position!: NotificationPosition;
    exception!: Error | null;
    throwException!: boolean;
}

declare global {
    interface WindowEventMap {
        [NOTIFICATION_EVENT_NAME]: NotificationEvent;
    }
}

export const NOTIFICATION_EVENT_NAME = 'shared-ui.notification';

export class NotificationEvent extends Event {
    constructor(public configuration: NotificationConfiguration) {
        super(NOTIFICATION_EVENT_NAME);
    }
}
