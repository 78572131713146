import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconDefinition } from './models';
import { APPLICATION_ICONS } from './tokens';
import { SHARED_ICONS } from './shared-icons';
import { IconModule } from '@visurel/iconify-angular';
import { AppIconsService } from './app-icons.service';

@NgModule({
    declarations: [],
    imports: [CommonModule, IconModule],
    exports: [IconModule],
})
export class AppIconsModule {
    static forRoot(icons: Record<string, IconDefinition>): ModuleWithProviders<AppIconsRootModule> {
        return {
            ngModule: AppIconsRootModule,
            providers: [AppIconsService, { provide: APPLICATION_ICONS, useValue: { ...SHARED_ICONS, ...icons } }],
        };
    }
}

@NgModule({
    imports: [AppIconsModule],
    exports: [AppIconsModule],
})
export class AppIconsRootModule {
    constructor(iconsService: AppIconsService) {
        iconsService.initialize();
    }
}
