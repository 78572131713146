import { Component, Injector } from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';
import { ClientConnectorService } from './services/client-connector.service';

@Component({
    selector: 'wt-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent {
    constructor(
        injector: Injector,
        private clientConnectorService: ClientConnectorService
    ) {
        super(injector);

        clientConnectorService.startToListen();
    }
}
