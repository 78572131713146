import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface ITokenObtainer {
    obtainToken(req: HttpRequest<any>): string;
    skipTokenInsertion(req: HttpRequest<any>): boolean;
}

@Injectable({ providedIn: 'root' })
export class DefaultTokenObtainer implements ITokenObtainer {
    

    obtainToken() {
        return '';
    }

    skipTokenInsertion(req: HttpRequest<any>) {
        return false;
    }
}
