import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export abstract class DisposableService implements OnDestroy {
    private _destroySubject = new Subject();
    protected _destroy = this._destroySubject.asObservable();

    ngOnDestroy(): void {
        this._destroySubject.next();
        this._destroySubject.complete();
    }
}
