export enum CertificateDeviceType {
    File = 'PKCS12',
    IdCard = 'AKKZIDCardStore',
    Phone = 'phone',
    Kaztoken = 'AKKaztokenStore',
    EToken = 'AKEToken5110Store',
}

export enum CertificateType {
    Auth = 'AUTHENTICATION',
    Sign = 'SIGNATURE',
}

export type NCALayerResponse<T> = {
    code: '200';
    responseObject: T;
};

export type CreateCAdESFromBase64Response = NCALayerResponse<string>;
export type GetActiveTokenResponse = NCALayerResponse<CertificateDeviceType[]>;
export type SignXMLsResponse = NCALayerResponse<string[]>;

export class NCALayerCanceledResponse {}
