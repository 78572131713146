<div class="flex flex-1 flex-col">
    <div
        class="
            flex flex-1
            bg-accent-main
            max-h-48
            pl-20
            pr-12
            items-center
            justify-between
        "
    >
        <div class="flex gap-16">
            <ic-icon [icon]="idocsLogo" width="61px" height="17px"></ic-icon>
            <span class="text-paragraph-2 text-white font-styrene">{{
                headerText
            }}</span>
        </div>
        <div class="text-header-4 text-white">
            <ic-icon
                [icon]="closeIcon"
                class="cursor-pointer"
                (click)="onCloseIconClick()"
            ></ic-icon>
        </div>
    </div>
    <router-outlet></router-outlet>

    <div class="flex justify-center items-center mt-18 pb-14">
        <span class="font-styrene text-label-2 text-primary-main">
            разработано в idocs
        </span>
    </div>
</div>
