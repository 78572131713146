import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TOKEN_OBTAINER } from './tokens';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './shared-auth.interceptor';
import {DefaultTokenObtainer, ITokenObtainer} from './token.obtainer';
import { BaseAuthService, SharedAuthService } from '@idocs/shared-logic/shared-auth/shared-auth.service';

@NgModule({
    declarations: [],
    imports: [CommonModule],
})
export class SharedAuthModule {
    static forRoot(configuration?: {
        tokenObtainer?: Type<ITokenObtainer>;
        authService?: Type<BaseAuthService>;
    }): ModuleWithProviders<SharedAuthRootModule> {
        return {
            ngModule: SharedAuthRootModule,
            providers: [
                { provide: TOKEN_OBTAINER, useClass: configuration?.tokenObtainer || DefaultTokenObtainer },
                { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
                { provide: BaseAuthService, useClass: configuration?.authService ?? SharedAuthService },
            ],
        };
    }
}

@NgModule({
    declarations: [],
    imports: [CommonModule, SharedAuthModule],
    exports: [SharedAuthModule],
})
export class SharedAuthRootModule {}
