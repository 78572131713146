import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationService } from './notification.service';
import { NotificationContainerComponent } from './notification-container/notification-container.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { NotificationComponent } from './notification/notification.component';
import { ButtonModule } from '../button';
import { IconModule } from '@visurel/iconify-angular';

@NgModule({
    declarations: [NotificationContainerComponent, NotificationComponent],
    imports: [CommonModule, OverlayModule, ButtonModule, IconModule],
})
export class NotificationModule {
    static provided = false;
    static forRoot(): ModuleWithProviders<NotificationRootModule> {
        if (NotificationModule.provided) {
            throw new Error('Only one root declaration allowed');
        }
        NotificationModule.provided = true;
        return {
            ngModule: NotificationRootModule,
            providers: [NotificationService],
        };
    }
}

@NgModule({
    imports: [NotificationModule],
    exports: [NotificationModule],
})
export class NotificationRootModule {
    constructor(notifications: NotificationService) {
        notifications.init();
    }
}
