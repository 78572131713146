import { Type } from '@angular/core';
import { RouteBuilder } from 'ng-utils/extensions/route.extensions';
import { NgAuthGuard } from '../guards/auth.guard';
import { AuthorizeRoute, DefaultPolicy, PolicyFailAction } from '../models/auth.models';

declare module 'ng-utils/extensions/route.extensions' {
    export interface RouteBuilder<TComponent extends Type<any> = Type<any>> {
        protectWith<TPolicy extends DefaultPolicy>(policy: TPolicy, action?: PolicyFailAction<TPolicy>): this;
    }
}

RouteBuilder.prototype.protectWith = function <TPolicy extends DefaultPolicy>(
    this: RouteBuilder,
    policy: TPolicy,
    action?: PolicyFailAction<TPolicy>
) {
    if (!this.canActivate?.includes(NgAuthGuard)) {
        this.canActivate = [...(this.canActivate || []), NgAuthGuard];
    }

    const protectRouteData: AuthorizeRoute['data'] = {
        policy: policy.name,
        onAuthFail: action,
    };

    this.data = { ...this.data, ...protectRouteData };
    return this as any;
};
