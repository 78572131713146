export enum FunctionalityType {
    DOCUMENT_SHARE = 0,
    DOCUMENT_LINK = 1,
    DOCUMENT_CREATE = 2,
    DOCUMENT_CANCELLATION_COUNT = 3,
    DOCUMENT_TEMPLATE = 4,
    DOCUMENT_MASS_DOWNLOAD = 5,
    WORKFLOW_INTERNAL = 6,
    WORKFLOW_TEMPLATE = 7,
    WORKFLOW_MASS_MATCHING = 8,
    WORKFLOW_MASS_SIGN = 9,
    WORKFLOW_MASS_CREATE = 10,
    WORKFLOW_COUNTERPARTY_COUNT = 11,
    PAYMENT_PER_COUNTERPARTY = 12,
    DOCUMENT_ASSIGN_RESPONSIBLE = 13,
    DOCUMENT_PACKAGE_ACCESS = 14,
    PAYROLL_PERSONNEL_MANAGEMENT_SIGNED = 15,
    CANCELLATION_DOCUMENTS = 16,
}
