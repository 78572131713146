<div class="flex flex-1 flex-col items-center gap-[104px] pt-26">
    <span
        class="
            text-paragraph-2 text-on-light-base
            whitespace-pre-wrap
            max-w-[360px]
        "
        >Соединение с сервером. Это может занять некоторое время, пожалуйста
        подождите.
    </span>
    <div class="text-[168px]">
        <ic-icon [icon]="checkCertificateIcon"></ic-icon>
    </div>
</div>
