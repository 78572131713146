export type AppColor = 'primary' | 'secondary' | 'accent' | 'info' | 'success' | 'warning' | 'danger' | 'neutral';
const colorConfig = require('./theme/colors.js');

export type AppColorVariants =
    | 'main'
    | 'extra-extra-lighter'
    | 'extra-lighter'
    | 'extra-light'
    | 'lighter'
    | 'light'
    | 'dark'
    | 'darker'
    | 'extra-dark'
    | 'extra-darker';

export function hexToRgb(hex: string, alpha = 1) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${alpha})`
        : null;
}

export type TailwindColors = {
    [key in AppColor]: { [key in AppColorVariants]: string };
} & {
    transparent: string;
    current: string;
    white: string;
    black: string;
};

export type TailwindTheme = {
    colors: TailwindColors;
};

export const tailwindTheme: TailwindTheme = {
    colors: colorConfig,
};
