import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { objectKeys } from '@dev-stream/utils';

export class SwaggerApiService {
    constructor(protected http: HttpClient, protected baseUrl: string) {}

    protected $MapParams(params: Record<string, any>): HttpParams {
        let httpParams = new HttpParams();
        for (const key of objectKeys(params)) {
            const value = params[key];
            if (value != null) {
                if (typeof value == 'string' || typeof value == 'number' || typeof value == 'boolean') {
                    httpParams = httpParams.set(key, value);
                } else if (value instanceof Date) {
                    httpParams = httpParams.set(key, value.toISOString());
                } else {
                    httpParams = httpParams.set(key, value.toString());
                }
            }
        }
        return httpParams;
    }
}

export type SwaggerApiRequestHeaders = HttpHeaders | { [header: string]: string | string[] };
