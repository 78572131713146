import { createPolicy } from 'ng-authorize';
import { LicenseAuthService } from './services/license-auth.service';

export namespace WidgetPolicies {
    export const Licensed = createPolicy(
        'widget.policies.Licensed',
        async (authService: LicenseAuthService) =>
            authService.isAuthenticated(),
        [LicenseAuthService]
    );
}

export const WIDGET_POLICIES = [WidgetPolicies.Licensed];
