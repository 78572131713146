import { CertificateIssuerType } from './CertificateIssuerType.model';
import { BillingPermissionModel } from './BillingPermissionModel.model';
import { BaseModel, StrictBaseModelPart, Property, EnumProperty, hasUnionKeys } from '@dev-stream/utils';

export class TokenResult extends BaseModel<TokenResult> {
    protected BASE_TYPE = TokenResult;
    'UserIIN'!: string | null;
    'Token'!: string | null;
    @Property(() => Date)
    'ExpirationDate'!: Date | null;
    'FirstName'!: string | null;
    'LastName'!: string | null;
    'GivenName'!: string | null;
    @EnumProperty(() => CertificateIssuerType)
    'IssuerType'!: CertificateIssuerType | null;
    'PhoneNumber'!: string | null;
    'Email'!: string | null;
    'AccountId'!: string | null;
    'CompanyId'!: string | null;
    'CompanyName'!: string | null;
    'CompanyHasLegacyDocuments'!: boolean | null;
    'EmployeeId'!: string | null;
    'HasMultipleAccounts'!: boolean | null;
    'IsNewCertificate'!: boolean | null;
    @Property(() => Date)
    'BirthDate'!: Date | null;
    'IsNotRegistered'!: boolean | null;
    'AccountRole'!: string | null;
    'TwoFactorAuthPassed'!: boolean | null;
    'OrgUnits'!: Array<string | null>;
    'IsDirector'!: boolean | null;
    'ImageUrl'!: string | null;
    'RolePermissions'!: Array<string | null>;
    'AccountPermissions'!: Array<string | null>;
    @Property(() => BillingPermissionModel)
    'BillingPermissions'!: Array<BillingPermissionModel | null>;

    public GetDefaultValue(): StrictBaseModelPart<TokenResult> {
        return {
            UserIIN: null,
            Token: null,
            ExpirationDate: null,
            FirstName: null,
            LastName: null,
            GivenName: null,
            IssuerType: null,
            PhoneNumber: null,
            Email: null,
            AccountId: null,
            CompanyId: null,
            CompanyName: null,
            CompanyHasLegacyDocuments: null,
            EmployeeId: null,
            HasMultipleAccounts: null,
            IsNewCertificate: null,
            BirthDate: null,
            IsNotRegistered: null,
            AccountRole: null,
            TwoFactorAuthPassed: null,
            OrgUnits: [],
            IsDirector: null,
            ImageUrl: null,
            RolePermissions: [],
            AccountPermissions: [],
            BillingPermissions: [],
        };
    }

    public static isTokenResult(o: any): o is TokenResult {
        return (
            o instanceof TokenResult ||
            hasUnionKeys(o, [
                'UserIIN',
                'Token',
                'ExpirationDate',
                'FirstName',
                'LastName',
                'GivenName',
                'IssuerType',
                'PhoneNumber',
                'Email',
                'AccountId',
                'CompanyId',
                'CompanyName',
                'CompanyHasLegacyDocuments',
                'EmployeeId',
                'HasMultipleAccounts',
                'IsNewCertificate',
                'BirthDate',
                'IsNotRegistered',
                'AccountRole',
                'TwoFactorAuthPassed',
                'OrgUnits',
                'IsDirector',
                'ImageUrl',
                'RolePermissions',
                'AccountPermissions',
                'BillingPermissions',
            ])
        );
    }
}
