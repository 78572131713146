import { Route, Router } from '@angular/router';

export type PolicyName = string | number;

export type DefaultPolicy = Policy<PolicyName, any>;
export type TPolicyName<T extends DefaultPolicy> = T['name'];
export type PolicyFailAction<TPolicy extends DefaultPolicy = DefaultPolicy> = (
    router: Router,
    ...injectables: Parameters<TPolicy['checkPolicy']>
) => Promise<any>;

export interface PolicyHandler<TPolicy extends DefaultPolicy = DefaultPolicy> {
    name: TPolicyName<TPolicy>;
    check: () => Promise<boolean>;
    doActionOnFail: (action: PolicyFailAction<TPolicy>) => Promise<void>;
}

export type CheckPolicyFunction = (...args: any) => Promise<boolean>;
export type CheckPolicyDependencies<T extends CheckPolicyFunction> = Types<
    Parameters<T>
>;

export interface Policy<
    T extends PolicyName,
    TDeps extends CheckPolicyFunction
> {
    name: T;
    checkPolicy: TDeps;
    deps?: CheckPolicyDependencies<TDeps>;
}

export interface PolicyExtraOptions {}

type Types<T> = {
    [key in keyof T]: new (...args: any) => T[key];
};

export const createPolicy = <
    TName extends PolicyName,
    TDeps extends CheckPolicyFunction
>(
    name: TName,
    checkPolicy: TDeps,
    deps: Types<Parameters<TDeps>>
): Policy<TName, TDeps> => ({
    name,
    deps,
    checkPolicy,
});

export type AuthorizeRoute<TPolicy extends Policy<any, any> = DefaultPolicy> =
    Route & {
        data: { policy: PolicyName; onAuthFail?: PolicyFailAction<TPolicy> };
    };

export interface IPermissionState {
    disabled?: boolean | null;
    tooltip: string;
}
