import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Injector,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';
import closeIcon from '@iconify/icons-mdi/close';
import {
    animate,
    sequence,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { AppColor } from '@idocs/tailwind';
import {
    NotificationVerticalPosition,
    NotificationHorizontalPosition,
    NotificationConfiguration,
} from '../models';
import { IconifyIcon } from '@idocs/icons';

const collapseAnimation = animate(
    '200ms ease',
    style({
        height: 0,
        marginTop: 0,
        padding: 0,
    })
);

const expandAnimations = [
    animate(
        '300ms ease',
        style({
            height: '*',
            marginTop: '20px',
            padding: '*',
        })
    ),
    animate('300ms ease'),
];

function generateTransitions(
    vertical: NotificationVerticalPosition,
    horizontal: NotificationHorizontalPosition
) {
    let transformationValue =
        horizontal == NotificationHorizontalPosition.Start ? -200 : 200;

    return [
        transition(`void => ${horizontal}-${vertical}`, [
            sequence([
                style({
                    height: 0,
                    marginTop: 0,
                    padding: 0,
                    ...(horizontal != NotificationHorizontalPosition.Center
                        ? { transform: `translateX(${transformationValue}%)` }
                        : {}),
                }),
                ...expandAnimations,
            ]),
        ]),

        transition(`${horizontal}-${vertical} => void`, [
            sequence([
                ...(horizontal != NotificationHorizontalPosition.Center
                    ? [
                          animate(
                              '300ms ease',
                              style({
                                  transform: `translateX(${transformationValue}%)`,
                              })
                          ),
                      ]
                    : []),
                collapseAnimation,
            ]),
        ]),
    ];
}

const bgWithDarkText: AppColor[] = ['warning', 'neutral'];

@Component({
    selector: 'shared-ui-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    animations: [
        trigger('notification', [
            ...generateTransitions(
                NotificationVerticalPosition.Start,
                NotificationHorizontalPosition.Start
            ),
            ...generateTransitions(
                NotificationVerticalPosition.Start,
                NotificationHorizontalPosition.Center
            ),
            ...generateTransitions(
                NotificationVerticalPosition.Start,
                NotificationHorizontalPosition.End
            ),
            ...generateTransitions(
                NotificationVerticalPosition.Center,
                NotificationHorizontalPosition.Start
            ),
            ...generateTransitions(
                NotificationVerticalPosition.Center,
                NotificationHorizontalPosition.Center
            ),
            ...generateTransitions(
                NotificationVerticalPosition.Center,
                NotificationHorizontalPosition.End
            ),
            ...generateTransitions(
                NotificationVerticalPosition.End,
                NotificationHorizontalPosition.Start
            ),
            ...generateTransitions(
                NotificationVerticalPosition.End,
                NotificationHorizontalPosition.Center
            ),
            ...generateTransitions(
                NotificationVerticalPosition.End,
                NotificationHorizontalPosition.End
            ),
        ]),
    ],
})
export class NotificationComponent extends BaseComponent implements OnInit {
    @HostBinding('@notification')
    get animationState() {
        return `${this.configuration?.position?.horizontal}-${this.configuration?.position?.vertical}`;
    }

    private _configuration?: NotificationConfiguration;
    public get configuration(): NotificationConfiguration | undefined {
        return this._configuration;
    }
    @Input()
    public set configuration(v: NotificationConfiguration | undefined) {
        this._configuration = v;
        this.updateElement();
    }

    @Output()
    close = new EventEmitter();
    closeIcon: IconifyIcon = closeIcon;

    constructor(injector: Injector, private ref: ElementRef<HTMLDivElement>) {
        super(injector);
        this.displayContents = false;
    }

    updateElement() {
        const result =
            this.ref.nativeElement.className.match(/bg-[a-zA-Z]-[a-zA-Z]/);
        if (result && result.length) {
            this.ref.nativeElement.classList.remove(result[0]);
        }
        this.ref.nativeElement.classList.remove(
            'text-white',
            'text-primary-main'
        );
        this.ref.nativeElement.classList.add(
            `bg-${this.configuration?.color}-main`
        );
        this.ref.nativeElement.classList.add(
            this.configuration?.color &&
                bgWithDarkText.includes(this.configuration.color)
                ? 'text-primary-main'
                : 'text-white'
        );
    }

    ngOnInit(): void {}
}
