import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';
import { idocsWidgetLogo } from '@idocs/icons';
import closeIcon from '@iconify/icons-ic/round-close';
import { LicenseAuthService } from '../services/license-auth.service';
import { takeUntil } from 'rxjs/operators';
import { ClientConnectorService } from '../services/client-connector.service';
import { ICloseWidgetMessage, MessageType } from '@idocs-kz/widgets';
import { v4 as uuid } from 'uuid';

@Component({
    selector: 'wt-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent extends BaseComponent implements OnInit {
    idocsLogo = idocsWidgetLogo;
    closeIcon = closeIcon;

    headerText = '';

    constructor(
        injector: Injector,
        private service: LicenseAuthService,
        private connectorService: ClientConnectorService
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.service
            .getChoosedWidget()
            .pipe(takeUntil(this.destroyed))
            .subscribe((widgetTitle) => {
                this.headerText = widgetTitle;
            });
    }

    onCloseIconClick() {
        this.connectorService.sendMessage(<ICloseWidgetMessage>{
            id: uuid(),
            type: MessageType.Close,
        });
    }
}
