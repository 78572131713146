export enum ErrorCode {
    GENERAL_ERROR = 0,
    NOT_FOUND = 1,
    USER_NOT_FOUND = 2,
    LOGIN_ATTEMPT_COUNT_EXCEEDED = 3,
    INVALID_PASSWORD = 4,
    EMPTY_FILE = 5,
    INVALID_FILE_EXTENSION = 6,
    INVALID_CERT = 7,
    NOT_CURRENT_CERT = 8,
    NOT_CEO = 9,
    EMPTY_CERT_PIN = 10,
    INVALID_CERT_PIN = 11,
    EXPIRED_CERT = 12,
    REVOKED_CERT = 13,
    INVALID_CERT_VALIDATION = 14,
    USER_ACCOUNT_EXISTS = 15,
    VERIFICATION_2FA_FAILED = 16,
    EMAIL_ALREADY_IN_USE = 17,
    EMPLOYEE_ALREADY_SIGNED_DOCUMENT = 20,
    CERTIFICATE_IS_REVOKED = 100,
    CERTIFICATE_IS_EXPIRED = 101,
    CERTIFICATE_HAS_WRONG_TYPE_FOR_ACTION = 102,
    XML_TO_SIGN_PARSING_ERROR = 103,
    SIGN_CMS_IS_INVALID = 104,
    SIGN_TSP_IS_INVALID = 105,
    CONTENT_IS_NOT_IN_BASE64_FORMAT = 106,
    FIELD_VALIDATION_TABLE_INVALID = 200,
    FIELD_VALIDATION_INVALID = 201,
    COUNTERPARTY_SELF_CREATE = 300,
    REDIRECT_TO_MAIN_PAGE = 1001,
    EMPLOYEE_ALREADY_ASSIGNED_TO_THE_SAME_COMPANY = 1200,
    EMPLOYEE_ALREADY_ASSIGNED_TO_ANOTHER_COMPANY = 1201,
    NULL_DATA_OR_NOT_ENOUGH_DATA_PROVIDED = 1202,
    ESF_CONNECTION_FAILED = 1203,
    ESF_REQUEST_NOT_FOUND = 1204,
    FILE_NOT_FOUND = 1205,
    APPLICATION_ROLE_NOT_FOUND = 1206,
    REQUIRED_PROPERTY_NOT_PROVIDED = 1207,
    ORGANIZATION_UNIT_ALREADY_EXISTS = 1208,
    ORGANIZATION_UNIT_NOT_FOUND = 1209,
    CERTIFICATE_PARSING_ERROR = 1210,
    MS_WORD_TO_PDF_ERROR = 2001,
    MS_EXCEL_TO_PDF_ERROR = 2002,
}
