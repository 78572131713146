import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StartingPageComponent } from './starting-page/starting-page.component';
import { IconModule } from '@visurel/iconify-angular';
import { AppIconsModule } from '@idocs/shared-ui/app-icons';
import { NgNcalayerModule } from 'ng-ncalayer';
import { NgAuthorizeModule } from 'ng-authorize';
import { WIDGET_POLICIES } from './app.auth-policies';
import { LayoutComponent } from './layout/layout.component';
import { HttpClientModule } from '@angular/common/http';
import { NotificationModule } from '@idocs/shared-ui/notification';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedAuthModule } from '@idocs/shared-logic';
import { WidgetTokenObtainer } from './services/widget-token-obtainer.service';
import { LicenseAuthService } from './services/license-auth.service';
import { WidgetsServerApiModule } from '../../../../libraries/api/src/widgets/services/widgets-server-api.module';
import { environment } from '../environments/environment';

@NgModule({
    declarations: [AppComponent, StartingPageComponent, LayoutComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        IconModule,
        HttpClientModule,
        AppIconsModule.forRoot({}),
        NgNcalayerModule.forRoot(),
        NgAuthorizeModule.forRoot(WIDGET_POLICIES),
        SharedAuthModule.forRoot({
            tokenObtainer: WidgetTokenObtainer,
            authService: LicenseAuthService,
        }),
        NotificationModule.forRoot(),
        WidgetsServerApiModule.forRoot(`${environment.apiServerUrl}`),
    ],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
