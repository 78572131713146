import { objectKeys } from '@dev-stream/utils';

export type ButtonColor = 'primary' | 'secondary' | 'accent' | 'info' | 'success' | 'warning' | 'danger' | 'neutral' | 'white';

export type ButtonType = 'default' | 'raised' | 'icon' | 'outline' | 'link' | 'bordered' | 'rounded' | 'custom';

export type ButtonTypeMap<T> = {
    [Key in ButtonType]: T;
};

export type ButtonColorMap<T> = {
    [Key in ButtonColor]: T;
};

export type ButtonStyles = ButtonColorMap<string[]>;
export type ButtonRipples = ButtonColorMap<string | null>;
export type ButtonShimmerColors = ButtonColorMap<string | null>;

export function extendStyles(styles: ButtonStyles, extendWith: string[]): ButtonStyles {
    return objectKeys(styles).reduce((result, key) => {
        return {
            ...result,
            [key]: [...styles[key], ...extendWith],
        };
    }, styles as ButtonStyles);
}

export function mergeStyles(a: ButtonStyles, b: ButtonStyles): ButtonStyles {
    return objectKeys(a).reduce((result, key) => {
        var classes = [...a[key], ...b[key]];
        classes = classes.filter((v, i, a) => a.indexOf(v) === i);
        return {
            ...result,
            [key]: classes,
        };
    }, a as ButtonStyles);
}
