import { IconDefinition } from './models';

const icons = [
    'nca-layer-file',
    'nca-layer-file-disabled',
    'nca-layer-kaztoken',
    'nca-layer-kaztoken-disabled',
    'nca-layer-id-card',
    'nca-layer-id-card-disabled',
    'nca-layer-e-token',
    'nca-layer-e-token-disabled',
    'doc-space-one',
    'doc-space-one-disabled',
    'doc-space-multiple',
    'doc-space-multiple-disabled',
    'folders-space',
    'folders-space-disabled',
    'docs-multiple',
    'docs-multiple-disabled',
    'docs-with-cogs',
    'docs-with-cogs-disabled',
    'pay-method',
    'pay-method-disabled',
    'password-recovery',
    'password-recovery-disabled',
    'instructions',
    'xmas-logo',
    'widget-logo',
    'archive',
    'cancel',
    'check',
    'edit-file',
    'search',
    'verified',
];

export const SHARED_ICONS = icons.reduce((definitions, cur) => {
    return {
        ...definitions,
        [cur]: {
            body: require(`!!raw-loader?!./../assets/icons/${cur}.svg`).default,
        },
    };
}, {} as Record<string, IconDefinition>);
