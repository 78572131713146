import { Injectable, OnDestroy } from '@angular/core';
import { NcaLayerService } from './ncalayer.service';
import { catchError, exhaustMap, takeUntil } from 'rxjs/operators';
import { interval, of, ReplaySubject, Subject } from 'rxjs';
import { CertificateDeviceType } from './models';
import { ConnectionStatus } from './ncalayer.connection';

@Injectable()
export class NcalayerDeviceWatcherService implements OnDestroy {
    private destroyedSubject = new Subject();
    private destroyed = this.destroyedSubject.asObservable();

    private devicesSubject = new ReplaySubject<CertificateDeviceType[]>();
    public devices = this.devicesSubject.asObservable();

    public stopSubject = new Subject();

    constructor(private service: NcaLayerService) {
        this.service.connection.statusChange().pipe(takeUntil(this.destroyed)).subscribe(this.statusChanged.bind(this));
    }

    ngOnDestroy() {
        this.destroyedSubject.next();
        this.destroyedSubject.complete();
    }

    private statusChanged(status: ConnectionStatus) {
        if (status == ConnectionStatus.Opened) {
            this.startWatchingDevices();
        } else {
            this.stopWatchingDevices();
            this.devicesSubject.next([]);
        }
    }

    public stopWatchingDevices() {
        this.stopSubject.next();
    }

    public startWatchingDevices() {
        this.stopWatchingDevices();
        interval(2000)
            .pipe(
                takeUntil(this.stopSubject.asObservable()),
                takeUntil(this.destroyed),
                exhaustMap((_) =>
                    this.service.getActiveTokens().pipe(
                        takeUntil(this.destroyed),
                        catchError((_) => of(null))
                    )
                )
            )
            .subscribe((response) => {
                const tokens = response?.responseObject ?? [];
                this.devicesSubject.next(tokens);
            });
    }
}
