import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector, NgZone } from '@angular/core';
import { NotificationContainerComponent } from './notification-container/notification-container.component';
import {
    NotificationConfiguration,
    NotificationContainer,
    NotificationHorizontalPosition,
    NotificationPosition,
    NotificationVerticalPosition,
    NOTIFICATION_EVENT_NAME,
} from './models';

@Injectable()
export class NotificationService {
    private containers: NotificationContainer<NotificationContainerComponent>[] = [];

    constructor(private overlay: Overlay, private ngZone: NgZone) {}

    init() {
        if (this.containers.length) {
            throw new Error('Notifications already initialized');
        }

        for (let vertical = NotificationVerticalPosition.Start; vertical <= NotificationVerticalPosition.End; vertical++) {
            for (
                let horizontal = NotificationHorizontalPosition.Start;
                horizontal <= NotificationHorizontalPosition.End;
                horizontal++
            ) {
                const container = this.createContainer({ horizontal, vertical });
                this.containers.push(container);
            }
        }
        window.addEventListener(NOTIFICATION_EVENT_NAME, (event) => {
            this.ngZone.run(() => {
                this.showNotification(event.configuration);
            });
        });
    }

    private createContainer(position: NotificationPosition) {
        let positionStrategy = this.overlay.position().global();

        if (position.vertical == NotificationVerticalPosition.Start) {
            positionStrategy = positionStrategy.top('0px');
        } else if (position.vertical == NotificationVerticalPosition.Center) {
            positionStrategy = positionStrategy.centerVertically();
        } else {
            positionStrategy.bottom('0px');
        }

        if (position.horizontal == NotificationHorizontalPosition.Start) {
            positionStrategy = positionStrategy.left('0px');
        } else if (position.horizontal == NotificationHorizontalPosition.Center) {
            positionStrategy = positionStrategy.centerHorizontally();
        } else {
            positionStrategy.right('0px');
        }

        const overlayRef = this.overlay.create({
            hasBackdrop: false,
            panelClass: 'notification-container-panel',
            disposeOnNavigation: false,
            positionStrategy,
        });

        overlayRef.hostElement.classList.add('notification-overlay');

        const containerRef = overlayRef.attach(
            new ComponentPortal(
                NotificationContainerComponent,
                null,
                Injector.create({
                    providers: [{ provide: OverlayRef, useValue: overlayRef }],
                })
            )
        );

        return {
            position,
            overlayRef,
            containerRef,
        };
    }

    public showNotification(configuration: NotificationConfiguration) {
        configuration = new NotificationConfiguration(configuration);
        const container = this.containers.find(
            (c) =>
                c.position.horizontal == configuration?.position?.horizontal &&
                configuration.position.vertical == c.position.vertical
        );
        container?.containerRef.instance.showNotification(configuration);
        if (configuration.exception && configuration.throwException) {
            throw configuration.exception;
        }
    }
}
