import {
    BaseModel,
    EnumProperty,
    Property,
    StrictBaseModelPart,
} from '@dev-stream/utils';
import {
    IdocsServerEnvironment,
    PortalUrls,
} from '@idocs/shared-logic/server-environment';

export class ApplicationEnvironment extends BaseModel<ApplicationEnvironment> {
    GetDefaultValue(): StrictBaseModelPart<ApplicationEnvironment, never> {
        return {
            apiServerUrl: '',
            cloudpaymentsPublicKey: '',
            corporateServerUrl: '',
            froalaApiKey: '',
            identityServerUrl: '',
            isProductionMode: false,
            portalUrls: new PortalUrls(),
            env: IdocsServerEnvironment.Local,
        };
    }

    protected BASE_TYPE = ApplicationEnvironment;

    identityServerUrl!: string;
    apiServerUrl!: string;
    isProductionMode!: boolean;
    corporateServerUrl!: string;
    froalaApiKey!: string;
    cloudpaymentsPublicKey!: string;
    @EnumProperty(() => IdocsServerEnvironment)
    env!: IdocsServerEnvironment;
    @Property(() => PortalUrls)
    portalUrls!: PortalUrls;
}
