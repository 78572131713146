export function parseNcalayerResponse(response: string): object | null {
    try {
        return JSON.parse(response);
    } catch {
        return null;
    }
}

export function hasCode<T = object>(obj: any): obj is { code: string } & T {
    return 'code' in obj;
}

export const NCALAYER_RESPONSES: ((response: object) => boolean)[] = [(response) => 'code' in response];
