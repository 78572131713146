import { Inject, Injectable } from '@angular/core';
import { Either, left, right } from '@sweet-monads/either';
import { IconService } from '@visurel/iconify-angular';
import { IconDefinition } from './models';
import { APPLICATION_ICONS } from './tokens';

@Injectable()
export class AppIconsService {
    constructor(
        @Inject(APPLICATION_ICONS) private applicationIcons: Record<string, IconDefinition>,
        private iconsRegistry: IconService
    ) {}

    public registerIcons(icons: Record<string, IconDefinition>) {
        Object.keys(icons).forEach((iconKey) => {
            this.iconsRegistry.register(iconKey, icons[iconKey]);
        });
    }

    initialize() {
        this.registerIcons(this.applicationIcons);
    }

    public getIcon(iconName: string): Either<Error, IconDefinition> {
        try {
            const definition = this.iconsRegistry.get(iconName) as IconDefinition;
            return right(definition);
        } catch (ex) {
            return left(ex as Error);
        }
    }
}
