import { BaseModel, StrictBaseModelPart } from '@dev-stream/utils';

export class SigningDocument extends BaseModel<SigningDocument> {
    BASE_TYPE = SigningDocument;

    GetDefaultValue(): StrictBaseModelPart<SigningDocument, never> {
        return {};
    }

    DocumentID?: string;
    DocumentName?: string;
    DocumentContentName?: string;
    DocumentExtensionMimeType?: string;
    Signatures?: File[];
    SourceContent?: Blob;
}
