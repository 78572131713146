import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
} from '@angular/common/http';
import { ProceedSignedContentRp, SigningDocument } from '../models';
import { eitherify, mapLeft, mapRight } from '@dev-stream/utils';
import {
    BASE_URL,
    WidgetsServerApiRootModule,
} from './widgets-server-api.module';
import { SwaggerApiService } from '@idocs/api/identity/services/SwaggerApiService.service';

@Injectable({
    providedIn: WidgetsServerApiRootModule,
})
export class SigningApiService extends SwaggerApiService {
    constructor(
        private httpService: HttpClient,
        @Optional() @Inject(BASE_URL) baseUrl: string
    ) {
        super(httpService, baseUrl);
    }

    getLicenseMetadata() {
        const response = this.httpService.get(`${this.baseUrl}/api/license`);

        return response.pipe(
            eitherify(),
            mapRight((response) => response),
            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }

    getHealthCheck() {
        const response = this.httpService.get(
            `${this.baseUrl}/api/healthcheck`
        );

        return response.pipe(
            eitherify(),
            mapRight((response) => response),
            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }

    postSignedFilesForValidation(
        model: SigningDocument[],
        headers: HttpHeaders = new HttpHeaders({
            Accept: '*/*',
        })
    ) {
        const formData = this.getFormData(model);
        const response = this.httpService.request(
            'post',
            `${this.baseUrl}/api/widget/signed-content`,
            {
                headers: headers,
                responseType: 'json',
                body: formData,
            }
        );

        return response.pipe(
            eitherify(),
            mapRight((response) => new ProceedSignedContentRp(response)),
            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }

    getValidatedZipFile(
        id: string,
        headers: HttpHeaders = new HttpHeaders({
            Accept: '*/*',
        })
    ) {
        const response = this.httpService.request(
            'get',
            `${this.baseUrl}/api/widget/signed-content/${id}`,
            {
                headers: headers,
                responseType: 'blob',
            }
        );

        return response.pipe(
            eitherify(),
            mapRight((response) => response),
            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }

    getFormData(model: SigningDocument[]) {
        const formData = new FormData();
        for (let [index, m] of model.entries()) {
            formData.append(
                `SignedContents[${index}].DocumentId`,
                m.DocumentID ?? ''
            );
            formData.append(
                `SignedContents[${index}].DocumentName`,
                m.DocumentName ?? ''
            );
            formData.append(
                `SignedContents[${index}].DocumentContentName`,
                m.DocumentContentName ?? ''
            );
            formData.append(
                `SignedContents[${index}].DocumentExtensionMimeType`,
                m.DocumentExtensionMimeType ?? ''
            );
            formData.append(
                `SignedContents[${index}].Signature`,
                m.Signatures ? m.Signatures[0] : ''
            );
            formData.append(
                `SignedContents[${index}].SourceContent`,
                m.SourceContent ?? ''
            );
        }
        return formData;
    }
}
