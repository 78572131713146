import { IIdentityConnectorAccount } from '@idocskz/identity-connector';
import { TokenResult } from '@idocs/api/identity/models/TokenResult.model';
import { BaseModel, Property, StrictBaseModelPart } from '@dev-stream/utils';
import { guid } from 'ng-utils/utils';

export class SharedAccountModel extends BaseModel<SharedAccountModel> implements IIdentityConnectorAccount {
    protected BASE_TYPE = SharedAccountModel;
    id!: string;
    @Property(() => TokenResult)
    tokenResult!: TokenResult;

    GetDefaultValue(): StrictBaseModelPart<SharedAccountModel, never> {
        return { tokenResult: new TokenResult(), id: guid() };
    }
}
