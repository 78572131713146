import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteBuilder } from 'ng-utils/extensions';
import { StartingPageComponent } from './starting-page/starting-page.component';
import { WidgetPolicies } from './app.auth-policies';
import { LayoutComponent } from './layout/layout.component';

const routes: Routes = [
    RouteBuilder.withPath('')
        .withLayout(LayoutComponent)
        .addChildren(
            RouteBuilder.withPath('').asRedirectRoute('starting-page').build(),
            RouteBuilder.withPath('starting-page')
                .withComponent(StartingPageComponent)
                .build(),
            RouteBuilder.withPath('signing')
                .protectWith(
                    WidgetPolicies.Licensed,
                    async (router) => await router.navigate(['starting-page'])
                )
                .withLoadChildren(() =>
                    import(
                        './widgets/signing-widget/signing-widget.module'
                    ).then((m) => m.SigningWidgetModule)
                )
                .build()
        )

        .build(),
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
