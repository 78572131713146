import { BaseModel, StrictBaseModelPart } from '@dev-stream/utils';

/**
 *  interface that defines OData query params structure
 */
export interface ODataQueryVm {
    $select?: string | null;
    $expand?: string | null;
    $filter?: string | null;
    $orderby?: string | null;
    $top?: number | null;
    $skip?: number | null;
    $count?: boolean | null;
}

/**
 *  model to be used where action execution is required, such as in context menus, bulk operations and etc.
 */
export class ActionVm<TValue = any> extends BaseModel<ActionVm<TValue>> {
    GetDefaultValue(): StrictBaseModelPart<ActionVm, never> {
        return {
            title: '',
            action: () => {
                console.log('this action must be implemented!');
            },
        };
    }
    protected BASE_TYPE = ActionVm;
    title!: string;
    action!: (val: TValue) => any;
}

export enum CertificateIssuerType {
    INDIVIDUAL = 0,
    ORGANIZATION = 1,
    ENTERPRENEUR = 2,
    UNKNOWN = 3,
}
