import { Injectable } from '@angular/core';

@Injectable()
export abstract class BaseAuthService {
    abstract authenticate(): any;
}

@Injectable()
export class SharedAuthService extends BaseAuthService {
    authenticate(): any {}
}
