<div class="button-wrapper" [matTooltip]="tooltip ?? ''">
    <button
        class="flex gap-8"
        matRipple
        [matRippleCentered]="appearance == 'icon'"
        [matRippleColor]="rippleColor || ''"
        [ngClass]="buttonStyles"
        [class.loading]="isLoading | async"
        [disabled]="disabled || (isLoading | async)"
        [attr.appearance]="appearance"
        [attr.color]="color"
        [style.--shimmer-background]="shimmerColor"
    >
        <ng-content></ng-content>
    </button>
</div>
