import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DisposableService } from '../../../../../libraries/cloudpayments/src/lib/services/disposable.service';
import { Subject } from 'rxjs';
import { IMessage } from '@idocs-kz/widgets';

@Injectable({
    providedIn: 'root',
})
export class ClientConnectorService extends DisposableService {
    stopListening?: () => void;

    clientUrl = '*';

    responseID?: string;

    private renderer: Renderer2;

    private _clientMessage: Subject<any> = new Subject();

    constructor(private rendererFactory: RendererFactory2) {
        super();
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    public startToListen() {
        this.stopListening = this.renderer.listen(
            'window',
            'message',
            this._handleMessage.bind(this)
        );
    }

    public sendMessage<TData = any>(message: IMessage<TData>) {
        if (!parent) {
            return;
        }
        parent.postMessage(message, this.clientUrl);
    }

    private _handleMessage(event: MessageEvent) {
        console.log('recieved message in iframe', event);
        if (this.clientUrl === '*') {
            this.clientUrl = event.origin;
        }

        if (this.clientUrl !== event.origin) {
            return;
        }

        this._clientMessage.next(event.data);
    }

    public getClientMessage() {
        return this._clientMessage.asObservable();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        if (this.stopListening) {
            this.stopListening();
        }
    }
}
