import { FactoryProvider, ModuleWithProviders, NgModule } from '@angular/core';
import { NgAuthDirective } from './directives/auth.directive';
import { NgAuthPipe } from './pipes/auth.pipe';
import {
    Policy,
    PolicyExtraOptions,
    PolicyHandler,
    PolicyName,
} from './models/auth.models';
import { AUTHORIZE_POLICIES } from './tokens';
import { NgAuthorizeService } from './services/ng-authorize.service';
import { Router } from '@angular/router';
import { AuthDisableDirective } from './directives/auth-disable.directive';

@NgModule({
    declarations: [NgAuthDirective, NgAuthPipe, AuthDisableDirective],
    imports: [],
    exports: [NgAuthDirective, NgAuthPipe, AuthDisableDirective],
})
export class NgAuthorizeModule {
    static forRoot(
        policies: Policy<PolicyName, any>[],
        options?: PolicyExtraOptions
    ): ModuleWithProviders<NgAuthorizeModule> {
        return {
            ngModule: NgAuthorizeModule,
            providers: [
                policies.map(
                    (policy) =>
                        <FactoryProvider>{
                            provide: AUTHORIZE_POLICIES,
                            useFactory: (router: Router, ...args: any) =>
                                <PolicyHandler>{
                                    name: policy.name,
                                    check: () => policy.checkPolicy(...args),
                                    doActionOnFail: (action) =>
                                        action(router, ...args),
                                },
                            deps: [Router, ...(policy.deps || [])],
                            multi: true,
                        }
                ),
                NgAuthorizeService,
            ],
        };
    }
}
