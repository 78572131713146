import { FunctionalityType } from './FunctionalityType.model';
import { BaseModel, StrictBaseModelPart, EnumProperty, hasUnionKeys } from '@dev-stream/utils';

export class BillingPermissionModel extends BaseModel<BillingPermissionModel> {
    protected BASE_TYPE = BillingPermissionModel;
    @EnumProperty(() => FunctionalityType)
    'Action'!: FunctionalityType | null;
    'IsUnlimited'!: boolean | null;
    'Limit'!: number | null;

    public GetDefaultValue(): StrictBaseModelPart<BillingPermissionModel> {
        return {
            Action: null,
            IsUnlimited: null,
            Limit: null,
        };
    }

    public static isBillingPermissionModel(o: any): o is BillingPermissionModel {
        return o instanceof BillingPermissionModel || hasUnionKeys(o, ['Action', 'IsUnlimited', 'Limit']);
    }
}
