<div class="font-bold" *ngIf="configuration?.title">{{ configuration?.title }}</div>
<div>{{ configuration?.content }}</div>

<shared-ui-button
    appearance="icon"
    color="primary"
    class="opacity-80 top-2 right-2"
    [iconSize]="16"
    [stylePosition]="'absolute'"
    (click)="close.emit()"
>
    <ic-icon [icon]="closeIcon"></ic-icon>
</shared-ui-button>
