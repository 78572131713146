import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { AuthorizeRoute } from '../models/auth.models';
import { NgAuthorizeService } from '../services/ng-authorize.service';

@Injectable({ providedIn: 'root' })
export class NgAuthGuard implements CanActivate, CanActivateChild {
    constructor(private ngAuthorizeService: NgAuthorizeService) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const data = route?.data as AuthorizeRoute['data'];
        if (!data.policy) {
            throw new Error('Policy name not provided in route.data.policy');
        }
        const result = await this.ngAuthorizeService.checkPolicy(data.policy);
        if (!result && data.onAuthFail != null) {
            await this.ngAuthorizeService.doActionOnFail(data.policy, data.onAuthFail);
        }

        return result;
    }

    async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.canActivate(route, state);
    }
}
