import { Injectable } from '@angular/core';
import {
    CertificateDeviceType,
    CertificateType,
    CreateCAdESFromBase64Response,
    GetActiveTokenResponse,
    SignXMLsResponse,
} from './models';
import { NcalayerConnection } from './ncalayer.connection';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class NcaLayerService {
    constructor(public connection: NcalayerConnection) {}

    getActiveTokens() {
        const request = {
            method: 'getActiveTokens',
            module: 'kz.gov.pki.knca.commonUtils',
        };
        return this.makeRequest<GetActiveTokenResponse>(request).pipe(
            map((response) => {
                response.responseObject.unshift(CertificateDeviceType.File);
                return response;
            })
        );
    }

    signXmls(
        device: CertificateDeviceType,
        certificateType: string,
        xmlsToSign: any
    ) {
        const request = {
            method: 'signXmls',
            module: 'kz.gov.pki.knca.commonUtils',
            args: [device, certificateType, xmlsToSign, '', ''],
        };
        return this.makeRequest<SignXMLsResponse>(request);
    }

    createCAdESFromBase64(
        device: CertificateDeviceType,
        deviceType: CertificateType,
        base64string: string,
        addTimestamp: boolean = true
    ) {
        const request = {
            method: 'createCMSSignatureFromBase64',
            module: 'kz.gov.pki.knca.commonUtils',
            args: [device, deviceType, base64string, addTimestamp],
        };
        return this.makeRequest<CreateCAdESFromBase64Response>(request);
    }

    private makeRequest<T>(request: any): Observable<T> {
        return this.connection.sendMessage(JSON.stringify(request));
    }

    isCancelResponse(
        res: any
    ): res is { code: 500; message: 'action.canceled' } {
        return (
            typeof res == 'object' &&
            res.code == 500 &&
            res.message == 'action.canceled'
        );
    }
}
