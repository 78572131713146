import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';
import {
    hasCode,
    NCALAYER_RESPONSES,
    parseNcalayerResponse,
} from './ncalayer.responses';

export enum ConnectionStatus {
    Opening,
    Closed,
    Opened,
}

@Injectable({ providedIn: 'root' })
export class NcalayerConnection implements OnDestroy {
    private destroyedSubject = new Subject();
    protected destroyed = this.destroyedSubject.asObservable();

    private ncaLayerSocketUrl = 'wss://127.0.0.1:13579';
    private socket?: WebSocket;

    private statusSubject = new BehaviorSubject<ConnectionStatus>(
        ConnectionStatus.Closed
    );
    private _status: ConnectionStatus = ConnectionStatus.Closed;
    public get status() {
        return this._status;
    }

    private reconnectionTimers = [1000, 2000, 5000];
    private reconnectionTimer = 0;

    private requestsQueue: Subject<any>[] = [];

    constructor(private _ngZone: NgZone) {
        this.statusChange()
            .pipe(takeUntil(this.destroyed))
            .subscribe((status) => {
                if (
                    this.status == ConnectionStatus.Opening &&
                    status == ConnectionStatus.Closed
                ) {
                    this.reconnectionTimer = Math.max(
                        this.reconnectionTimer,
                        this.reconnectionTimers.length - 1
                    );
                } else {
                    if (status == ConnectionStatus.Opened) {
                        this.reconnectionTimer = 0;
                    }
                }
                this._status = status;
            });
    }

    private changeStatus(status: ConnectionStatus) {
        if (status == this._status) {
            return;
        }
        this.statusSubject.next(status);
    }

    openConnection() {
        if (
            (this.status == ConnectionStatus.Opening ||
                this.status == ConnectionStatus.Opened) &&
            (this.socket?.readyState == WebSocket.OPEN ||
                this.socket?.readyState == WebSocket.CONNECTING)
        ) {
            return;
        }

        this.socket = new WebSocket(this.ncaLayerSocketUrl);
        this.changeStatus(ConnectionStatus.Opening);
        this.socket.onerror = this.handleSocketError.bind(this);
        this.socket.onclose = this.closed.bind(this);
        this.socket.onopen = this.opened.bind(this);
        this.socket.onmessage = this.handleMessage.bind(this);
    }

    public statusChange() {
        return this.statusSubject.asObservable();
    }

    ngOnDestroy(): void {
        this.destroyedSubject.next();
        this.destroyedSubject.complete();
    }

    private handleSocketError(_: Event) {}

    private opened(_: Event) {
        if (!this.socket) {
            return;
        }
        this.changeStatus(ConnectionStatus.Opened);
        this.socket.onerror = this.handleSocketError.bind(this);
    }

    private closed(_: CloseEvent): any {
        this.changeStatus(ConnectionStatus.Closed);
        this._ngZone.runOutsideAngular(() =>
            setTimeout(async () => {
                await this.openConnection();
            }, this.reconnectionTimers[this.reconnectionTimer])
        );
    }

    private handleMessage(e: MessageEvent) {
        const response = parseNcalayerResponse(e.data);
        if (
            response == null ||
            !NCALAYER_RESPONSES.some((isResponse) => isResponse(response)) ||
            this.requestsQueue.length == 0
        ) {
            return;
        }
        const subject = this.requestsQueue.shift();
        if (hasCode(response) && response.code == '200') {
            subject?.next(response);
        } else {
            subject?.error(response);
        }
        subject?.complete();
    }

    sendMessage(message: string): Observable<any> {
        if (this.status == ConnectionStatus.Opened && this.socket) {
            this.socket.send(message);
            const subject = new Subject<any>();
            this.requestsQueue.push(subject);
            return subject.asObservable();
        }

        this.openConnection();
        return this.statusChange().pipe(
            filter((status) => status == ConnectionStatus.Opened),
            take(1),
            switchMap(() => this.sendMessage(message))
        );
    }
}
