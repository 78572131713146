import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';
import { clockWaitIcon, IconifyIcon } from '@idocs/icons';
import { ClientConnectorService } from '../services/client-connector.service';
import { LicenseAuthService } from '../services/license-auth.service';
import { takeUntil } from 'rxjs/operators';
import {
    IBroadcastMessage,
    ICheckLicenseData,
    isCheckLicenseMessage,
    IMessage,
    MessageType,
} from '@idocs-kz/widgets';
import { v4 as uuid } from 'uuid';

console.log('Message Type: ', MessageType);

@Component({
    selector: 'wt-starting-page',
    templateUrl: './starting-page.component.html',
    styleUrls: ['./starting-page.component.scss'],
})
export class StartingPageComponent extends BaseComponent implements OnInit {
    checkCertificateIcon: IconifyIcon = clockWaitIcon;

    constructor(
        injector: Injector,
        private licenseAuthService: LicenseAuthService,
        private clientConnectorService: ClientConnectorService
    ) {
        super(injector);
        clientConnectorService.sendMessage(<IBroadcastMessage>{
            id: uuid(),
            type: MessageType.Broadcast,
        });
    }

    ngOnInit(): void {
        this.clientConnectorService
            .getClientMessage()
            .pipe(takeUntil(this.destroyed))
            .subscribe((message) => {
                if (isCheckLicenseMessage(message)) {
                    this.validateLicenseKey(message);
                    console.log('received', message);
                }
            });
    }

    validateLicenseKey(message: IMessage<ICheckLicenseData>) {
        this.clientConnectorService.responseID = message.id;
        this.licenseAuthService.checkLicense(message);
    }
}
