import { BaseModel, StrictBaseModelPart } from '@dev-stream/utils';

export enum IdocsServerEnvironment {
    Local,
    Refactor,
    Beta,
    Production,
}

export class PortalUrls extends BaseModel<PortalUrls> {
    protected BASE_TYPE = PortalUrls;

    cabinet!: string;
    identity!: string;
    billing!: string;
    sign!: string;
    company!: string;
    signWidgetServerUrl!: string;

    GetDefaultValue(): StrictBaseModelPart<PortalUrls, never> {
        return {
            cabinet: 'https://localhost',
            billing: 'https://localhost:4202',
            identity: 'https://localhost:4201',
            sign: 'https://localhost:4203',
            company: 'https://localhost:4205',
            signWidgetServerUrl: 'https://localhost:4206',
        };
    }
}
