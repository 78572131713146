import { Injectable } from '@angular/core';
import { ITokenObtainer } from '@idocs/shared-logic';
import { HttpRequest } from '@angular/common/http';
import { LicenseAuthService } from './license-auth.service';

@Injectable({
    providedIn: 'root',
})
export class WidgetTokenObtainer implements ITokenObtainer {
    constructor(protected authService: LicenseAuthService) {}

    obtainToken(_: HttpRequest<any>): string {
        return `Bearer ${this.authService.token}`;
    }

    skipTokenInsertion(req: HttpRequest<any>): boolean {
        return false;
    }
}
