import { Type } from '@angular/core';
import { LoadChildren, Resolve, Route, Routes } from '@angular/router';
import { objectKeys } from '@dev-stream/utils';

export type ExtendRouteWith<TExtendWith extends Route, TExtend extends Route = Route> = TExtend & TExtendWith;

type FinishedRouterBuilder =
    | RouteBuilderWithComponent
    | RouteBuilderWithLayout
    | RouteBuilderWithRedirect
    | LazyLoadedChildrenRouteBuilder
    | MultipleChildrendRouteBuilder;

export type RouteBuilderChildren = Routes;

export class RouteBuilder<TComponent extends Type<any> = Type<any>> {
    public readonly path?: string;
    public readonly component?: TComponent;
    public readonly to?: string;
    public readonly pathMatch?: 'full' | 'prefix';
    public data?: Readonly<{ [key: string]: any }>;
    public children: ReadonlyArray<Route> = [];
    public canActivate: ReadonlyArray<any> = [];
    public readonly loadChildren?: LoadChildren;
    public resolve?: Readonly<{ [key: string]: any }>;

    constructor(...builders: Partial<RouteBuilder>[]) {
        Object.assign(this, ...builders);
    }

    public static withPath(path: string) {
        return new RouteBuilderWithPath(path);
    }

    public extendData(data: { [key: string]: any }) {
        this.data = { ...this.data, ...data };
        return this;
    }

    addResolver<TData>(dataKey: string | number, resolver: Type<Resolve<TData>>) {
        this.resolve = { ...this.resolve, [dataKey]: resolver };
        return this;
    }

    protected build(): Route {
        var route: Route = {
            path: this.path,
            component: this.component,
            redirectTo: this.to,
            pathMatch: this.pathMatch,
            children: [...this.children],
            canActivate: [...this.canActivate],
            data: this.data,
            loadChildren: this.loadChildren,
            resolve: this.resolve,
        };

        objectKeys(route).forEach((key) => {
            if (route[key] == null || (Array.isArray(route[key]) && route[key]?.length == 0)) {
                delete route[key];
            }
        });

        return route;
    }
}

class RouteBuilderWithPath extends RouteBuilder {
    constructor(path: string, ...builders: RouteBuilder[]) {
        super(...builders, { path });
    }

    public withComponent<TComponent extends Type<any>>(layout: TComponent) {
        return new RouteBuilderWithComponent<TComponent>(layout, this);
    }

    public withLayout<TComponent extends Type<any>>(layout: TComponent) {
        return new RouteBuilderWithLayout<TComponent>(layout, this);
    }

    public asRedirectRoute(to: string, pathMatch: 'full' | 'prefix' = 'prefix') {
        return new RouteBuilderWithRedirect(to, pathMatch, this);
    }

    withLoadChildren(loadChildren: LoadChildren) {
        return new LazyLoadedChildrenRouteBuilder(loadChildren, this);
    }

    addChild(path: string, buildAciton: (builder: RouteBuilderWithPath) => FinishedRouterBuilder) {
        return new MultipleChildrendRouteBuilder(this).addChild(path, buildAciton);
    }

    addChildren(...children: RouteBuilderChildren) {
        return new MultipleChildrendRouteBuilder(this).addChildren(children);
    }
}

class RouteBuilderWithRedirect extends RouteBuilder {
    constructor(to: string, pathMatch: 'full' | 'prefix', ...builders: Partial<RouteBuilder>[]) {
        super(...builders, { to, pathMatch });
    }

    public build(): Route {
        return super.build();
    }
}

class RouteBuilderWithComponent<TComponent extends Type<any> = Type<any>> extends RouteBuilder<TComponent> {
    constructor(component: TComponent, ...builders: Partial<RouteBuilder>[]) {
        super(...builders, { component });
    }

    public build(): Route {
        return super.build();
    }
}

class RouteBuilderWithLayout<TComponent extends Type<any> = Type<any>> extends RouteBuilder<TComponent> {
    constructor(component: TComponent, ...builders: Partial<RouteBuilder>[]) {
        super(...builders, { component });
    }

    withLoadChildren(loadChildren: LoadChildren) {
        return new LazyLoadedChildrenRouteBuilder(loadChildren, this);
    }

    addChild(path: string, buildAciton: (builder: RouteBuilderWithPath) => FinishedRouterBuilder) {
        return new MultipleChildrendRouteBuilder(this).addChild(path, buildAciton);
    }

    addChildren(...children: RouteBuilderChildren) {
        return new MultipleChildrendRouteBuilder(this).addChildren(children);
    }

    public build(): Route {
        return super.build();
    }
}

class MultipleChildrendRouteBuilder<TComponent extends Type<any> = Type<any>> extends RouteBuilder<TComponent> {
    constructor(...builders: Partial<RouteBuilder>[]) {
        super(...builders);
    }

    addChild(path: string, buildAciton: (builder: RouteBuilderWithPath) => FinishedRouterBuilder) {
        const builder = buildAciton(RouteBuilder.withPath(path));
        const childRoute = builder.build();
        this.children = [...this.children, childRoute];
        return this;
    }

    addChildren(children: RouteBuilderChildren) {
        this.children = [...this.children, ...children];
        return this;
    }

    public build(): Route {
        return super.build();
    }
}

class LazyLoadedChildrenRouteBuilder<TComponent extends Type<any> = Type<any>> extends RouteBuilder<TComponent> {
    constructor(loadChildren: LoadChildren, ...builders: Partial<RouteBuilder>[]) {
        super(...builders, { loadChildren });
    }

    public build(): Route {
        return super.build();
    }
}
