import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';
import { NotificationConfiguration } from '../models';

@Component({
    selector: 'shared-ui-notification-container',
    templateUrl: './notification-container.component.html',
    styleUrls: ['./notification-container.component.scss'],
})
export class NotificationContainerComponent
    extends BaseComponent
    implements OnInit
{
    notifications: NotificationConfiguration[] = [];

    showNotification(configuration: NotificationConfiguration) {
        this.notifications.push(configuration);
        if (configuration.duration) {
            setTimeout(() => {
                this.remove(configuration);
            }, configuration.duration);
        }
    }

    constructor(injector: Injector) {
        super(injector);
    }

    remove(notification: NotificationConfiguration) {
        const index = this.notifications.findIndex((n) => n == notification);
        this.notifications.splice(index, 1);
    }

    ngOnInit(): void {}
}
