import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button/button.component';
import { MatRippleModule } from '@angular/material/core';
import { ShimmerModule } from '@sreyaj/ng-shimmer';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [ButtonComponent],
    imports: [CommonModule, MatRippleModule, ShimmerModule, MatTooltipModule],
    exports: [ButtonComponent],
})
export class ButtonModule {}
